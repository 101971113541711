.input {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid #dedede;
}
.input:focus {
  outline-color: #33bfa3;
}
