.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;

  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 1rem;
  background-color: #33bfa3;

  cursor: pointer;
  transition: all 0.3s;
}
.btn:hover,
.btn:focus {
  background-color: #fa9856;
}
