@media (max-width: 1023px) {
  .btn--loadMore {
    width: 100%;
    padding: 1rem 1.5rem;
  }
}
@media (max-width: 700px) {
  html {
    font-size: 14px;
  }
  .content {
    padding: 0 0.75rem;
  }
  .searchForm h1 {
    font-size: 1.75rem;
  }
  .searchForm {
    padding: 7rem 1rem 3rem;
  }
  .grid {
    display: flex;
    width: auto;
    gap: 0.75rem;
  }
  .grid__item {
    position: relative;
    margin-bottom: 0.75rem;
  }
  .grid__item img {
    min-height: 100px;
  }
}
@media (max-width: 600px) {
  .searchForm form {
    gap: 0;
  }
  .searchForm input {
    padding-right: 60px;
  }
  .searchForm button {
    position: absolute;
    right: 0;
    height: calc(100% - 2px);
    font-size: 0;
    gap: 0;
  }
}
