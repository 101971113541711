@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
}
html {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
}
body {
  margin: 0;
  font-optical-sizing: auto;
  font-variation-settings: 'wdth' 100;
}
body * {
  font-family: 'Noto Sans', sans-serif;
}
.content {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 1rem;
}
header {
  position: fixed;
  top: 0;
  padding: 1rem;
  width: 100%;
  background: #fff;
  box-shadow: 0 25px 50px -12px rgba(93, 99, 112, 0.4);
  z-index: 10;
}
header .logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1rem;
  font-weight: 900;
  color: #225874;
}
header .logo img {
  height: 40px;
  transition: all 0.3s;
}
header .logo.scrolled img {
  height: 28px;
}
.searchForm {
  padding: 6rem 1rem 4rem;
  text-align: center;
}
.searchForm h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #225874;
}
.searchForm h1 span {
  color: #fcb283;
}
.searchForm form {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.searchForm input {
  width: 100%;
  max-width: 700px;
}
.searchForm button::after {
  content: '';
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='m8.5,0h-4C2.019,0,0,2.019,0,4.5v4c0,.827.673,1.5,1.5,1.5h7c.827,0,1.5-.673,1.5-1.5V1.5c0-.827-.673-1.5-1.5-1.5Zm.5,8.5c0,.276-.224.5-.5.5H1.5c-.276,0-.5-.224-.5-.5v-4c0-1.93,1.57-3.5,3.5-3.5h4c.276,0,.5.224.5.5v7Zm4.5,1.5h7c.827,0,1.5-.673,1.5-1.5v-4c0-2.481-2.019-4.5-4.5-4.5h-4c-.827,0-1.5.673-1.5,1.5v7c0,.827.673,1.5,1.5,1.5Zm-.5-8.5c0-.276.224-.5.5-.5h4c1.93,0,3.5,1.57,3.5,3.5v4c0,.276-.224.5-.5.5h-7c-.276,0-.5-.224-.5-.5V1.5Zm-4.5,10.5H1.5c-.827,0-1.5.673-1.5,1.5v4c0,2.481,2.019,4.5,4.5,4.5h4c.827,0,1.5-.673,1.5-1.5v-7c0-.827-.673-1.5-1.5-1.5Zm.5,8.5c0,.276-.224.5-.5.5h-4c-1.93,0-3.5-1.57-3.5-3.5v-4c0-.276.224-.5.5-.5h7c.276,0,.5.224.5.5v7Zm14.854,2.646l-3-3c.705-.862,1.146-1.948,1.146-3.146,0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5c1.198,0,2.284-.441,3.146-1.146l3,3c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512,0-.707Zm-6.854-2.146c-2.206,0-4-1.794-4-4s1.794-4,4-4,4,1.794,4,4-1.794,4-4,4Z' fill='%23FFF'/></svg>");
}
.grid {
  display: flex;
  width: auto;
  gap: 1rem;
}
.grid__column {
  max-width: 20%;
}
.grid__column--4 {
  max-width: 25%;
}
.grid__column--3 {
  max-width: 33%;
}
.grid__column--2 {
  max-width: 50%;
}
.grid__item {
  position: relative;
  margin-bottom: 1rem;
  cursor: pointer;
}
.grid__item img {
  width: 100%;
  min-height: 120px;
  margin-bottom: -4px;
  object-fit: cover;
}
.grid__item h3 {
  position: absolute;
  bottom: 0;
  font-size: 1rem;
  font-weight: 200;
  color: #fff;
  padding: 0 0.5rem;
  margin: 0;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.grid__item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 88, 115, 0.6);
  opacity: 0;
  text-decoration: none;
  transition: all 0.3s;
}
.grid__item:hover::before,
.grid__item:focus::before {
  opacity: 1;
}
.grid__item:hover h3,
.grid__item:focus h3 {
  bottom: 0.5rem;
  opacity: 1;
}
.btn--loadMore {
  margin: 3rem auto;
}
.btn--loadMore::after {
  content: '';
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M24,12c0,6.617-5.383,12-12,12-1.166,0-2.319-.167-3.428-.497-.265-.079-.416-.357-.337-.622,.08-.264,.354-.414,.622-.337,1.016,.302,2.073,.456,3.143,.456,6.065,0,11-4.935,11-11S18.065,1,12,1c-1.07,0-2.127,.153-3.143,.456-.265,.078-.542-.073-.622-.337-.079-.265,.072-.543,.337-.622,1.109-.33,2.262-.497,3.428-.497,6.617,0,12,5.383,12,12Zm-11.998,6h0c.535,0,1.037-.208,1.421-.593l3.436-3.56c.192-.199,.187-.515-.012-.707-.199-.191-.516-.186-.707,.013l-3.43,3.554c-.06,.06-.136,.093-.208,.134l-.002-10.341c0-.276-.224-.5-.5-.5s-.5,.224-.5,.5l.002,10.342c-.07-.04-.144-.072-.202-.13l-3.441-3.56c-.191-.199-.508-.204-.707-.012-.198,.192-.204,.508-.012,.707l3.447,3.566c.378,.378,.88,.586,1.415,.586Zm-5.993,3.228c-.88-.573-1.673-1.268-2.359-2.065-.18-.209-.496-.233-.705-.054-.209,.18-.233,.496-.054,.705,.748,.87,1.613,1.628,2.572,2.252,.084,.055,.179,.081,.272,.081,.164,0,.324-.08,.419-.227,.151-.231,.085-.542-.146-.692ZM1.109,13.558c-.039-.273-.292-.462-.565-.425-.273,.039-.464,.292-.425,.565,.16,1.128,.48,2.227,.952,3.266,.083,.184,.265,.293,.456,.293,.069,0,.139-.014,.207-.045,.251-.114,.362-.411,.248-.662-.433-.951-.726-1.958-.872-2.991Zm.624-6.769c-.254-.117-.548-.002-.662,.248-.472,1.039-.792,2.137-.952,3.266-.039,.273,.152,.526,.425,.565,.023,.003,.047,.005,.07,.005,.245,0,.459-.18,.495-.43,.146-1.033,.439-2.04,.872-2.991,.114-.251,.003-.548-.248-.662Zm1.547-1.788c.14,0,.28-.059,.379-.174,.685-.794,1.475-1.486,2.35-2.055,.231-.15,.297-.46,.146-.691-.15-.231-.46-.298-.691-.146-.954,.62-1.816,1.374-2.562,2.24-.18,.209-.157,.525,.053,.705,.094,.081,.21,.121,.326,.121Z' fill='%23FFF'/></svg>");
}
footer {
  margin-top: 3rem;
  padding: 1rem;
  background-color: #33bfa3;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 200;
  color: #fff;
}
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 88, 115, 0.8);
  z-index: 99;
  cursor: pointer;
}
.lightbox__content {
  position: relative;
  gap: 0.5rem;
  color: #fff;
  width: 90%;
  height: 80%;
  flex-direction: column;
}
.lightbox,
.lightbox__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox__content img {
  max-width: 100%;
  max-height: 100%;
}
.lightbox__content button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
}
